import {
  SELECTED_CATEGORY, SELECTED_AMOUNT,
  SELECTED_QUOTAS, SELECTED_RATE, RESET_STATE
} from '../actions/ActionTypes'


import { 
  STORAGE_KEY_SELECTED_CATEGORY, 
  STORAGE_KEY_SELECTED_AMOUNT,
  STORAGE_KEY_SELECTED_QUOTAS,
} from "../utils/keys";


import settings from '../assets/jsons/settings.json'

let selectedCategory = localStorage.getItem(STORAGE_KEY_SELECTED_CATEGORY) ? Number(localStorage.getItem(STORAGE_KEY_SELECTED_CATEGORY)) : 0;
let amount = localStorage.getItem(STORAGE_KEY_SELECTED_AMOUNT) ? Number(localStorage.getItem(STORAGE_KEY_SELECTED_AMOUNT)) : 0;
let quota = localStorage.getItem(STORAGE_KEY_SELECTED_QUOTAS) ? Number(localStorage.getItem(STORAGE_KEY_SELECTED_QUOTAS)) : 2;
let interestRate = (settings) ? Number(settings.interestRate) : 24;


const initialState = {
  selectedCategory: selectedCategory,
  amount: amount,
  quota: quota,
  interestRate: interestRate
}

//
//  {\__/}
// ( • . •)
// / >   / > 📓 gimme state
//
const card = (state = initialState, action) => {

  switch (action.type) {

    case SELECTED_CATEGORY:
      localStorage.setItem(STORAGE_KEY_SELECTED_CATEGORY, action.payload)
      return { ...state, selectedCategory: Number(action.payload) }

    case SELECTED_AMOUNT:
      localStorage.setItem(STORAGE_KEY_SELECTED_AMOUNT,  action.payload)
      return { ...state, amount: Number(action.payload) }

    case SELECTED_QUOTAS:
      localStorage.setItem(STORAGE_KEY_SELECTED_QUOTAS,  action.payload)
      return { ...state, quota: Number(action.payload) }

    case SELECTED_RATE:
      return { ...state, interestRate: Number(action.payload) }

    case RESET_STATE:

      localStorage.setItem(STORAGE_KEY_SELECTED_AMOUNT,   0)
      localStorage.setItem(STORAGE_KEY_SELECTED_QUOTAS,   2)
      return {...state, amount: 0, quota: 2}

    default:
      return state
  }

}

export default card;