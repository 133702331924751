import React, { Component } from 'react';
import { createStore } from "redux";
import { Provider } from "react-redux";
import styled from 'styled-components';

import rootReducers from "./reducers"

// Importing components
import Alert from 'react-s-alert';
import Header from './components/Header';
import CardAmount from './components/CardAmount';
import CardCategory from './components/CardCategory';
import CardCuotas from './components/CardCuotas';
import CuotasFinalResults from './components/CuotasFinalResults';

import { 
  STORAGE_KEY_SELECTED_CATEGORY
} from "./utils/keys";


import './assets/custom-font.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

const CalculatorWrapper = styled.div`
/* Universal universal border box fix */
  box-sizing: border-box;
  *,
  ::before,
  ::after{
    box-sizing:inherit;
  }
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  right:0;
  overflow: hidden;
  font-size: calc(0.5em + 1vw);
`;


const store = createStore(rootReducers)

class Calculator extends Component {

  constructor() {
    super();

    this.state = {
      step: 1
    }

    this.changeStep = this.handleStepChange.bind(this);
  }

  componentDidMount() {

    // if long press event exists then ...
    let selectedCagegory = localStorage.getItem(STORAGE_KEY_SELECTED_CATEGORY)

    if(selectedCagegory && Number(selectedCagegory) !== 0) {
      this.setState({ step: 2 })
    }
  
    let isSafari = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (isSafari && (!localStorage.getItem('flag') || Boolean(localStorage.getItem('flag')) === false)) {
      Alert.info('Accede más rápido a esta app. Instala esta página a tu celular.', {
          position: 'bottom',
          effect: 'stackslide',
          timeout: 'none',
          onClose: function () {
            localStorage.setItem('flag', true)
          }
      });
      return;
    }
  }

  componentDidUpdate(prevProps) {
    if(this.state.step === 1 ) {

      let selectedCagegory = localStorage.getItem(STORAGE_KEY_SELECTED_CATEGORY)
      if(selectedCagegory && Number(selectedCagegory) !== 0) {
        this.setState({ step: 2 })
      }
    }
  }
  
  handleStepChange = (step) => {
    this.setState({ step })
  }

  render() {
    return (
      <Provider store={store}>
        <CalculatorWrapper className="App">
          <Header />
          <CardCategory cardTitle="Selecciona la categoría de comercio que manejas" isOpen={this.state.step  === 1} stepHandler={this.changeStep} />
          <CardAmount cardTitle="¿Qué monto deseas calcular en cuotas?" isOpen={this.state.step  === 2} stepHandler={this.changeStep} />
          <CardCuotas cardTitle="¿En cuántas cuotas deseas pagar?" isOpen={this.state.step  === 3} stepHandler={this.changeStep}/>
          <CuotasFinalResults isOpen={this.state.step  === 4} stepHandler={this.changeStep} />
          <Alert 
            stack={{limit: 1}}
          />
        </CalculatorWrapper>
      </Provider>
    );
  }
}

export default Calculator;