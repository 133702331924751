import React from 'react';
import styled from 'styled-components';
import posed from "react-pose";


const PosedSystemStatus = posed.div({
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
        transition: { duration: 250 }
    }
})

const SystemStatus = styled(PosedSystemStatus)`
    background-color: #66BB66;
    min-height: 120px;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 22px 0 22px;
    display:flex;
    justify-content: space-between;
    font-family: 'Trebuchet';
    font-weight: normal;
    opacity: 0;
    pointer-events: ${props => (!props.isVisible) ? 'none' : 'auto'};
`;

const Handle = styled.div`
    background-color:rgba(39, 93, 39, .5);
    height:4px;
    width:40px;
    border-radius:5px;
    position: absolute;
    top:5px;
    left: 44%;
`;

const Label = styled.span`
    color: #275D27;
    font-size:18px;
    font-weight:bold;
`;

const LabelValue = styled.span`
    color: white;
    font-size:18px;
    font-weight:bold;
`;

const CardStatus = (props) => {
    const { labelContent, labelValueContent, isVisible, onClick} = props;

    return(
        <SystemStatus isVisible={isVisible} pose={isVisible ? 'visible' : 'hidden' } onClick={onClick}>
            <Handle></Handle>
            <Label>{labelContent}</Label>
            <LabelValue>{labelValueContent}</LabelValue>
        </SystemStatus>
    )
};


export default CardStatus;