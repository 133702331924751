import React from 'react';
import styled from 'styled-components';

import EmptyState from "./empty-states/EmptyState";

import { sanitize } from '../utils/strings'

const CategoriesWrapper = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-height: calc(100vh - 20.2em);
    overflow-y:scroll;
    padding-bottom: 5.2em;
    align-items: start;
`;

const Category = styled.button`
    background-color: transparent;
    border: none;
    padding: 8px;
    outline: none;
`;

const Item = styled.div`
    background-color:white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 2px #ddd;
    border-radius:5px;
    padding:12px 8px 10px 8px;
    
    & img{
        width:50px;
        margin-top:5px;
    }
`;

const CategoryText = styled.div`
    text-align: center;
    font-size: 22px;
    margin-top: 7px;
    color: #555555;
    hyphens: auto;
    webkit-hyphens: auto;
    ms-hyphens: auto;
`;

const CategorySelector = (props) => {

    if(!props.categories) {
        return (<EmptyState resetInput={props.resetInput} />)
    }

    // let wrapperRef = React.createRef()
    // here needs to be an action that updates the redux store 
    let filterBy = props.filterBy.toLowerCase();

    let filteredCategories =
        props.categories
            .filter(category => {

                // if nothing is passed, then render as usual
                if (!filterBy) return true;

                // if filterBy matches a name, go through
                if (sanitize(category.name).toLowerCase().includes(filterBy))
                    return true;

                let containsSynonym = false;

                // if it doesn't match, then try to filter by synonyms
                if(category.synonyms) {
                    category.synonyms
                    .split(',')
                    .forEach(element => {
                        if (sanitize(element).toLowerCase().includes(filterBy)) {
                            containsSynonym = true;
                        }
                    });
                }

                // if it still doesn't match...
                // well it doesn't, get over it
                return containsSynonym;
            })
            
    return (
        <CategoriesWrapper>
            {
                filteredCategories.length ? (
                    filteredCategories.map(categoryItem => {
                        return (
                            <Category
                                key={categoryItem.id}
                                className={`category${categoryItem.id}`}
                                category={categoryItem.id}
                                onClick={props.onClick.bind(this, categoryItem.id)}>
                                <Item>
                                <CategoryText>{categoryItem.name}</CategoryText>
                                    <img src={`https://api.cuotasbhd.com${categoryItem.icon.url}`} alt={`Categoría ${categoryItem.name}`} />
                                </Item> 
                               
                            </Category>
                        )
                    })
                ) : (
                    <EmptyState resetInput={props.resetInput} />
                )
            }
        </CategoriesWrapper>
    )
};

export default CategorySelector;