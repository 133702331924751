import React from 'react';
import ReactDOM from 'react-dom';
import Calculator from './Calculator';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Calculator />, document.getElementById('root'));

// let doubleTouchStartTimestamp = 0;
// document.addEventListener("touchstart", function (event) {
//   let now = +(new Date());
//   if (doubleTouchStartTimestamp + 100 > now) {
//     console.log('you tried to double tap')
//     event.preventDefault();
//   };
//   doubleTouchStartTimestamp = now;
// }, { passive: false });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
