export const EXAMPLE = '';

export const SELECTED_CATEGORY  = 'app.state.category';
export const SELECTED_AMOUNT    = 'app.state.amount';
export const SELECTED_QUOTAS    = 'app.state.quotas';
export const SELECTED_RATE      = 'app.state.rate';
export const RESET_STATE        = 'app.state.reset';


export const GET_QUOTA_HISTORY      = 'app.state.history.get';
export const ADD_QUOTA_HISTORY      = 'app.state.history.add';
export const DELETE_QUOTA_HISTORY   = 'app.state.history.delete';