import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    max-width:275px;
`
const SupportiveInfoComp = styled.p`
    color: #333;
    text-align: left;
    font-family: 'Trebuchet';
    font-weight: normal;
    font-size: .7em;
    display: inline;
`
const Icon = styled.span`
    font-size: 2.5em;
    margin-top: 0.2em;
    margin-right: 0.4em;
    color: #66BB66;
    font-weight: 300;
`

const SupportiveInfo = (props) =>{
    const {annualInterestFee, monthInterestFee} = props;
    return(
        <Wrapper>
            <Icon>%</Icon>
            <SupportiveInfoComp>
                Tasa de interés anual de <strong>{annualInterestFee}% ({ Number(monthInterestFee).toFixed(2)}% mensual)</strong> incluída en el cálculo de las cuotas.
            </SupportiveInfoComp>
        </Wrapper>
    )
};

export default SupportiveInfo;