import React from 'react';
import styled from 'styled-components';

// Importing components
import Button from '../Button';

const Message = styled.div`
    text-align: center;
    margin-top: 1em;
    grid-column: 1 / 5;

    & p{
        font-size:1rem;
        font-family: Trebuchet;
        padding-left:1em;
        padding-right:1em;
    }

    & button{
        font-size:1rem;
    }
`

const EmptyState = (props) => {
    return(
        <Message>
           <p>Esta categoría de comercio no está disponible.</p>
            <Button textButton="Buscar otra categoría" onClickHandle={props.resetInput.bind(this)} />
        </Message>
    )
}

export default EmptyState; 