function PMT(ir, np, pv, fv, type)
{
  let pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0)
      return -(pv + fv)/np;

  pvif = Math.pow(1 + ir, np);
  pmt = - ir * pv * (pvif + fv) / (pvif - 1);

  if (type === 1)
      pmt /= (1 + ir);

  return pmt;
}

/**
 * 
 * @param period        - number of months
 * @param interest      - yearly interest rate.
 * @param present_value - The loan amount. 
*/
export function fees(period, interest, present_value)
{
  let ret = PMT(interest / 1200, period, -present_value);

  return Math.round(ret * 100) / 100;
}