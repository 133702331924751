import React from 'react';
import styled from 'styled-components';

const ButtonComponent = styled.button`
    background: linear-gradient(0deg, #50B044, #66C657);
    border-radius: 28px;
    border-style:none;
    color:white;
    width:100%;
    padding-top:0.9em;
    padding-bottom:0.9em;
    font-size: 18px;
    font-weight:bold;
    box-shadow: 0px 37px 45px -24px rgba(92, 92, 92, 0.80), 
0px 30px 15px -21px rgba(101, 197, 86, 0.30);
    outline: none;
    touch-action: manipulation;

    @media screen and (max-width: 340px) {
        padding-top: .5em;
        padding-bottom: .7em;
    }
`

const Button = (props) => {

    let { className, textButton, onClickHandle } = props;
    
    return(
        <ButtonComponent className={className} onClick={onClickHandle}>
            {textButton}
        </ButtonComponent>
    )
};

export default Button;