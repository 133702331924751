
/**
 * TODO: Implement Cache ?
 */
export async function getCategories ()
{
    return fetch(`https://api.cuotasbhd.com/categories`)
    .then(resp => resp.json())
    .catch(err => { console.error(err) })
}

/**
 * 
 */
export async function getCreditCards ()
{
    return fetch(`https://api.cuotasbhd.com/creditcards `)
    .then(resp => resp.json())
    .catch(err => { console.error(err) })
}