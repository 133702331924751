import React from 'react';
import styled from 'styled-components';

const CuotasInfo = styled.h2`
    color: black;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
    font-size: 1.4em;
    margin-left: auto;
    margin-right: auto;
    max-width:275px;

    & span{
        color: #66BB66;
    }
`;

const CuotasResults = (props) =>{
    const { amountToCalculate, cuotaToPay } = props;

    let formatedAmount = new Intl.NumberFormat('es-419', { style: 'decimal' }).format(amountToCalculate)
    let formatedQuota = new Intl.NumberFormat('es-419', { style: 'decimal' }).format(cuotaToPay)

    return(
        <CuotasInfo>
            Por <span>RD$ {formatedAmount}</span> vas a pagar <span>RD$ {formatedQuota}</span> mensuales.
        </CuotasInfo>
    )
};

export default CuotasResults;