import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import posed from 'react-pose';

// Importing components
import InputComponent from './Input';
import CategorySelector from './CategorySelector';
import CardStatus from './CardStatus';

import { STORAGE_KEY_REMEMBER_CATEGORY, STORAGE_KEY_SELECTED_CATEGORY } from '../utils/keys'

// Importing actions
import { getCategories } from "../services/index";
import { selectCategory, selectRate } from "../actions";


const PoseCard = posed.div({
    open: {
        top: 'calc(5.2em + 0%)',
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    },
    closed: {
        top: () => {
            let isSmallDevice = window.matchMedia('(max-width: 340px)').matches;
            return `calc(5.2em + ${ isSmallDevice ? '75%' : '72%' })`;
        },
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    }
});


const Card = styled(PoseCard)`
    z-index: 4;
    background: white;
    width: calc(100vw - 2em);
    min-height: 100vh;
    position: absolute;
    top: calc(5.2em + 0%);
    left:1em;
    right:1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    padding-left:1em;
    padding-right:1em;
    overflow: auto;

    & .category-btn{
        margin-top:0.75em;
    }
`;

const CardTitle = styled.h2`
    color: #66BB66;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
`;


class CardCategory extends React.Component {

    constructor() {
        super();

        this.state = {
            filterBy: '',
            categories: null,
            isOpen: true,
            isSystemCardVisible: false
        }

        this.onClick = this.handleOnClickEvent.bind(this);
        this.onInputChange = this.handleOnInputChange.bind(this);
        this.onSystemCardClick = this.handleSystemCardClick.bind(this);
        
        this.onLongPress = this.handleLongPress.bind(this)
        this.onLongPressRelease = this.handleLongPressRelease.bind(this)
        this.resetInput = this.handleResetInput.bind(this);

        this.card = React.createRef();
    }

    componentDidMount()
    {
        getCategories()
            .then(categoriesJson => {
                if(categoriesJson) {
                    this.setState({ categories: categoriesJson })
                }
            })
    }

    componentDidUpdate(prevProps) 
    {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                isOpen: this.props.isOpen,
                isSystemCardVisible: !this.props.isOpen
            });
        }

        // if(this.props.isOpen) {
        //     if(navigator.onLine) {

        //         fetch('/icon-categories/settings.json', {
        //             headers: {
        //                 "Content-Type": "application/json; charset=utf-8",
        //             },
        //         })
        //         .then(res => res.json())
        //         .then(settings => {
        //             console.log(settings)
        //             this.props.selectRate(settings.interestRate)
        //         })
        //         .catch(err => {
        //             console.error(err)
        //         })
        //     }
        // }
    }

    handleOnClickEvent = (categoryId) => {

        this.props.selectCategory(categoryId);
        
        this.setState({
            isOpen: !this.props.isOpen,
            isSystemCardVisible: true
        });

        this.props.stepHandler(2);
    }


    handleLongPress = (categoryId) => {
        this.longPressTimer = setTimeout(() => {
            
            let rememberCategory = localStorage.getItem(STORAGE_KEY_REMEMBER_CATEGORY);

            if(rememberCategory === 'true') {
                localStorage.setItem(STORAGE_KEY_REMEMBER_CATEGORY, false)
            } 
            else {
                localStorage.setItem(STORAGE_KEY_REMEMBER_CATEGORY, true)
            }

            this.props.selectCategory(categoryId)
        
            this.setState({
                isOpen: !this.props.isOpen,
                isSystemCardVisible: true
            });
    
            this.props.stepHandler(2)
        }, 1000);
    }
    
    handleLongPressRelease = () => {
        clearTimeout(this.longPressTimer);
    }
    

    handleOnInputChange = (event) => {
        let filterByText = event.target.value
        this.setState({ filterBy: filterByText });
    }

    handleSystemCardClick = () => {
        this.setState({ isOpen: true, isSystemCardVisible: false })
        this.props.selectCategory(0);
        this.props.stepHandler(1)
    }

    handleResetInput = () => {
        this.setState({ filterBy: '' })
    }

    render() {
        const { filterBy, isOpen, isSystemCardVisible } = this.state;
        const cardLabel = (this.state.categories) &&
        this.state.categories.find(category => {
            return Number(category.id) === Number(this.props.app.selectedCategory)
        })

        return (
            <Card
                ref={this.card} 
                pose={isOpen ? 'open' : 'closed'}>

                <CardStatus 
                    isVisible={isSystemCardVisible} 
                    labelContent="Tipo de comercio" 
                    labelValueContent={cardLabel ? cardLabel.name : ''} 
                    onClick={this.onSystemCardClick} />

                <CardTitle>{this.props.cardTitle}</CardTitle>
                <InputComponent 
                    alphaNumInput 
                    placeholder='Busca tu categoría de comercio' 
                    onChange={this.onInputChange} 
                    value={this.state.filterBy} />
                <CategorySelector 
                    categories={this.state.categories}
                    filterBy={filterBy} 
                    onClick={this.onClick.bind(this)} 
                    onLongPress={this.onLongPress.bind(this)}
                    onLongPressRelease={this.onLongPressRelease.bind(this)}
                    resetInput={this.resetInput.bind(this)} />
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState
})

export default connect(
    mapStateToProps,
    { selectCategory, selectRate}
)(CardCategory);