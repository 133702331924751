import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    background-color: #F0F0F0;
    color: #A9A9A9;
    text-align:${props => props.numberInput ? "right" : "left"};
    font-size:${props => props.numberInput ? "33px": "1em"};
    border-radius: 14px;
    padding-top:13px;
    padding-bottom: 15px;
    padding-left:15px;
    padding-right:15px;
    display:block;
    width:100%;
    height:50px;
    margin-bottom:10px;
    border-style: solid;
    border-width: 1px;
    border-color:transparent;
    z-index: 0;

    @media screen and (max-width: 340px) {
        padding: 10px 15px 10px 15px;
        height: 40px;
        font-size:${props => props.numberInput ? "25px": "1em"};
    }

    ::placeholder{
        color:#C7C7C7;
    }

    :focus{
        outline:none;
        border-style: solid;
        border-width: 1px;
        border-color:#ddd;
    }
`

const InputComponent = (props) => {
    const { className, alphaNumInput, numberInput, placeholder, readOnly, onChange, value } = props;

    if(alphaNumInput){
        return(
            <Input 
                alphaNumInput
                className={className}
                placeholder={placeholder} 
                onChange={onChange ? onChange : null}
                readOnly={readOnly ?  true : false}
                value={value && value} />
        )
    }
    if(numberInput){
        return(
            <Input 
                numberInput
                className={className}
                placeholder={placeholder} 
                onChange={onChange ? onChange : null}
                readOnly={readOnly ?  true : false}
                value={value ? value : ''} />
        )
    }
}

export default InputComponent;