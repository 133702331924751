import React from 'react';
import styled from 'styled-components';

const HeaderLogo = styled.div`
    text-align: center;
    margin-top: 1em;
`

const Header = (props) => {
    return(
        <HeaderLogo>
            <img src="images/cuotas-logo.png" alt="Logo cuotas BHD León" />
        </HeaderLogo>
    )
}

export default Header;