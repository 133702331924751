import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import uuidv4 from 'uuid/v4';
import posed from 'react-pose';


// Importing components
import Button from './Button';
import CardStatus from './CardStatus';
import CuotasPicker from './CuotasPicker';
import CuotasResults from './CuotasResults';
import SupportiveInfo from './SupportiveInfo';

import { getCategories } from "../services/index";
import { selectQuotas, addQuotaToHistory } from "../actions";
import { fees } from "../utils/math";


const PoseCard = posed.div({
    open: {
        top: 'calc(5.2em + 0%)',
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    },
    closed: {
        top: () => {
            let isSmallDevice = window.matchMedia('(max-width: 340px)').matches;
            return `calc(5.2em + ${ isSmallDevice ? '100%' : '64%' })`;
        },
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    }
});


const Card = styled(PoseCard)`
    z-index: 2;
    background: white;
    height: 87vh;
    width: calc(100vw - 2em);
    position: absolute;
    top: calc(0vh + 5.2em); /* <= resolves to the value that positions the card in the top */
    left:1em;
    right:1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    padding-left:1em;
    padding-right:1em;
`;

const CardTitle = styled.h2`
    color: #66BB66;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
`;


class CardCuotas extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedQuotas: 0,
            creditCardRate: 0,
            quotasLimit: 0,
            quota: 2,
            quotaToPay: 0,
            categories: null,
            isOpen: false,
            isSystemCardVisible: false
        }

        this.onNextClicked = this.handleSubmitEvent.bind(this);
        this.onIncrementClicked = this.handleIncrementEvent.bind(this);
        this.onDecrementClicked = this.handleDecrementEvent.bind(this);
        this.onSystemCardClick = this.handleSystemCardClick.bind(this);
    }


    componentDidMount()
    {
        Promise.all([ getCategories() ])
            .then(values => {

                let categories = values[0].find(category => category.id === this.props.app.selectedCategory);
               let quotasLimit = (categories) ? categories.quotesQuantity : 12;

                let creditCardRate = this.props.app.interestRate;

                this.setState({ 
                    quotasLimit, 
                    creditCardRate, 
                    categories: values[0]
                })
            })
    }

    componentDidUpdate(prevProps) 
    {
        if (this.props.isOpen !== prevProps.isOpen) {
 
            let quotaToPay = fees(2, this.props.app.interestRate, this.props.app.amount)

            this.setState({
                isOpen: this.props.isOpen,
                isSystemCardVisible: !this.props.isOpen,
                quota: 2,
                quotaToPay
            });
        }

        if (this.props.app.selectedCategory !== prevProps.app.selectedCategory) {
            let category = this.state.categories.find(category => category.id === this.props.app.selectedCategory);
            let quotasLimit = (category) ? category.quotesQuantity : 12;
            this.setState({ quotasLimit });
        }
    }

    handleIncrementEvent = () => {

        if (Number(this.state.quota) === Number(this.state.quotasLimit)) return;

        let quota = this.state.quota + 1;

        let quotaToPay = fees(quota, this.props.app.interestRate, this.props.app.amount)
        this.setState({ quota, quotaToPay })
    }
    

    handleDecrementEvent = () => {

        if (Number(this.state.quota) === 2) return;

        let quota = this.state.quota - 1;

        let quotaToPay = fees(quota, this.props.app.interestRate, this.props.app.amount)
        this.setState({ quota, quotaToPay })
    }

    handleSubmitEvent = () => {
        this.props.selectQuotas(this.state.quota)

        let quota = {
            id: uuidv4(),
            selectedCategory: this.props.app.selectedCategory,
            amount: this.props.app.amount,
            quota: this.state.quota,
            interestRate: this.props.app.interestRate,
            created_at: new Date()
        };

        this.props.addQuotaToHistory(quota)

        this.setState({
            isSystemCardVisible: true
        })

        this.props.stepHandler(4)
    }

    handleSystemCardClick = () => {
        this.setState({ isOpen: true, isSystemCardVisible: false })
        this.props.stepHandler(3)
    }

    render() {
        const { isOpen } = this.state;

        const { amount, interestRate } = this.props.app;
        const labelValue = `${this.props.app.quota} cuotas`;

        return (
            <Card pose={isOpen ? 'open' : 'closed'}>
                <CardStatus 
                    isVisible={this.state.isSystemCardVisible} 
                    labelContent="Cuotas" 
                    labelValueContent={labelValue ? labelValue : ''}
                    onClick={this.onSystemCardClick}  />
                <CardTitle>{this.props.cardTitle}</CardTitle>
                <CuotasPicker
                    selectedQuota={this.state.quota}
                    quotaLimit={this.state.quotasLimit}
                    onIncrement={this.onIncrementClicked}
                    onDecrement={this.onDecrementClicked} />
                <CuotasResults amountToCalculate={amount} cuotaToPay={this.state.quotaToPay} />
                <SupportiveInfo annualInterestFee={interestRate} monthInterestFee={interestRate / 12} />
                <Button textButton="Guardar Cálculo" onClickHandle={this.onNextClicked} />
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState
});

export default connect(
    mapStateToProps,
    { selectQuotas, addQuotaToHistory }
)(CardCuotas);