import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
    text-align: center;
    margin-top: 3em;
    grid-column: 1 / 5;

    & p{
        font-size:1rem;
        font-family: Trebuchet;
        padding-left:3em;
        padding-right:3em;
    }
`

const EmptyHistory = (props) => {
    return(
        <Message>
           <p>Historial de calculos vacío.</p>
        </Message>
    )
}

export default EmptyHistory; 