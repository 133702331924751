import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import posed from 'react-pose';
import Alert from 'react-s-alert'

// Importing components
import InputComponent from './Input';
import Button from './Button';
import NumKeyboard from './NumericKeyboard';
import CardStatus from './CardStatus';

import { selectAmount } from "../actions";


const PoseCard = posed.div({
    open: {
        top: 'calc(5.2em + 0%)',
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    },
    closed: {
        top: () => {
            let isSmallDevice = window.matchMedia('(max-width: 340px)').matches;
            return `calc(5.2em + ${ isSmallDevice ? '100%' : '72%' })`;
        },
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    }
});

const Card = styled(PoseCard)`
    z-index: 3;
    background: white;
    height: 87vh;
    width: calc(100vw - 2em);
    position: absolute;
    top: calc(0vh + 5.2em);
    /* top: calc(100vh - 8.5em); <= resolves to the value that positions the card in the bottom */
    left:1em;
    right:1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    padding-left:1em;
    padding-right:1em;
`;

const CardTitle = styled.h2`
    color: #66BB66;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
`;


class CardAmount extends React.Component {

    constructor() {
        super();

        this.state = {
            amount: '',
            isOpen: false,
            isSystemCardVisible: false
        }

        this.onNextClicked = this.handleSubmitEvent.bind(this);
        this.onNumPressed = this.handleOnNumberPressed.bind(this);
        this.onSystemCardClick = this.handleSystemCardClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                isOpen: this.props.isOpen,
                isSystemCardVisible: !this.props.isOpen,
                amount: '',
            });
        }
    }


    handleOnNumberPressed = (number) => {

        // handle special submission of numbers
        if (this.state.amount === '' && number === 0) return;

        // 54 = backspace
        if (number === 54) {

            if (this.state.amount === '') return;

            let amount = this.state.amount.slice(0, this.state.amount.length - 1);
            this.setState({ amount: amount });
            return;
        }

        // do logic to "add a number"
        let amount = `${this.state.amount + number}`;

        this.setState({ amount });
    }

    handleSubmitEvent = (event) => {
        if (this.state.amount === '') return;

        if (Number(this.state.amount) < 1000) {
            Alert.error('El monto mínimo es de RD$ 1,000.00', {
                position: 'top',
                effect: 'stackslide',
                timeout: 2000
            });
            return;
        }

        if (Number(this.state.amount) > 1000000) {
            Alert.error('El monto máximo es de RD$ 1,000,000.00', {
                position: 'top',
                effect: 'stackslide',
                timeout: 2000
            });
            return;
        }

        this.props.selectAmount(this.state.amount);

        this.setState({
            isSystemCardVisible: true
        })

        this.props.stepHandler(3)
    }

    handleSystemCardClick = () => {
        this.setState({ isOpen: true, isSystemCardVisible: false })
        this.props.stepHandler(2)
    }

    _format = (number) => new Intl.NumberFormat('es-419', { style: 'decimal' }).format(number)


    render() {
        const { isOpen } = this.state;
        const labelValue = this.props.app.amount;

        return (
            <Card pose={isOpen ? 'open' : 'closed'}>
                <CardStatus
                    isVisible={this.state.isSystemCardVisible}
                    labelContent="Monto a calcular"
                    labelValueContent={labelValue ? `RD$ ${this._format(labelValue)}` : ''}
                    onClick={this.onSystemCardClick} />
                <CardTitle>{this.props.cardTitle}</CardTitle>
                <InputComponent
                    numberInput
                    placeholder="RD$ 1,000"
                    value={this.state.amount ? this._format(this.state.amount) : false}
                    readOnly />
                <NumKeyboard onClick={this.onNumPressed} />
                <Button textButton="Continuar" onClickHandle={this.onNextClicked} />
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState
})


export default connect(
    mapStateToProps,
    { selectAmount }
)(CardAmount);
