let accent_map = {
  'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', // a
  'ç': 'c',                                                   // c
  'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',                     // e
  'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',                     // i
  'ñ': 'n',                                                   // n
  'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o', // o
  'ß': 's',                                                   // s
  'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',                     // u
  'ÿ': 'y'                                                    // y
};


export function sanitize(string)
{
  if (!string) { return ''; }

  let ret = '';
  for (let i = 0; i < string.length; i++) {
    ret += accent_map[string.charAt(i)] || string.charAt(i);
  }

  return ret;
};

