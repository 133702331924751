import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'
import { connect } from 'react-redux'
import styled from 'styled-components';
import Whirligig from 'react-whirligig'
import html2canvas from 'html2canvas';

// Importing components
import Button from './Button';
import EmptyHistory from './empty-states/EmptyHistory'

import { fees } from "../utils/math";
import { getQuotaHistory, resetState, deleteQuota } from "../actions";
import Header from './Header';

const CuotasHistoryWrapper = styled.div`
    z-index: 1;
    width: calc(100vw - 2em);
    position: absolute;
    /* top: calc(100vh - 8.5em); <= resolves to the value that positions the card in the bottom */
    top: calc(0vh + 4.2em); /* <= resolves to the value that positions the card in the top */
    left:1em;
    right:1em;
    display: ${props => props.isHidden ? "none" : "block"};

    & > :last-child{
        margin-top:1.5em;
    }
`;

const HistoryTitle = styled.h2`
    color: #2F692F;
    text-align: center;
    font-family: 'Trebuchet';
`;

const HistoryItem = styled.p`
    background-image: linear-gradient(1deg, #50B044, #66C657);
    border-radius: 7px;
    padding: 1em 1em;
    color: #2F692F;
    font-size: 1.4em;
    font-family: 'Trebuchet';

    & strong{
        color: white;
    }

    & button {
        margin-top: 1.5em;
        background: linear-gradient(0deg, hsla(112, 87%, 93%, 0.85), hsl(0, 0%, 100%));
        color: #2F692F;
        box-shadow: none;
        
        &.delete-btn{
            color:#2F692F;
            width:70%;
            padding-top:0.5em;
            padding-bottom:0.5em;
            font-size:15px;
        }
    }
`;

class CuotasFinalResults extends Component {

    constructor() {
        super();

        this.state = {
            quotaHistory: [],
            showCanvas: true
        }

        this.onNewQuotaClicked = this.handleOnNewQuotaClicked.bind(this);
        this.onShareClicked = this.handleOnSharedClicked.bind(this);
        this.onDeleteClicked = this.handleOnDeleteClicked.bind(this);

        this.html2CanvasOptions = {
            logging: false
        }
    }

    componentDidMount() {
        this.setState({
            quotaHistory: this.props.history.quotaHistory
        })
    }

    componentDidUpdate(prevProps) {
        if (this.state.quotaHistory.length !== prevProps.history.quotaHistory.length) {
            this.setState({
                quotaHistory: this.props.history.quotaHistory
            })
        }
    }

    /**
     * Handles the navigation flow to start from the beginning,
     * by using stepHandler parent function.
     */
    handleOnNewQuotaClicked = () => {
        this.props.resetState();
        this.props.stepHandler(1)
    }

    /**
    * Currently downloads an custom image of the selected quota.
    */
    handleOnSharedClicked = (quote) => {

        // let selectedCategory = categories.find(category => category.id === this.props.app.selectedCategory)

        let ref = document.querySelector(`#id-${quote.id}`).cloneNode(true);
        ref.style.position = 'absolute';
        ref.style.top = '-100vh';
        ref.style.left = '-100vw';

        let header = renderToString(<Header />)
        ref.insertAdjacentHTML('beforeend', header);

        let button = ref.querySelector('button')
        ref.removeChild(button);

        document.body.appendChild(ref)

        html2canvas(ref, this.html2CanvasOptions)
            .then(function (canvas) {
                // this.myRef = canvas

                // window.open(canvas.toDataURL("image/png"), "_blank");
                // let domImage = document.createElement('a');

                // domImage.href = canvas.toDataURL("image/png"); //.replace("image/png", "image/octet-stream");
                // domImage.download = `calculo_cuota_${selectedCategory.name.toLowerCase()}_${quote.quota}.png`;
                // domImage.click();

                // domImage.href = `whatsapp://send?text=Hello World!&photos=${canvas.toDataURL("image/png")}`;
                // domImage.download = `${quote.id}.png`;
                // domImage.click();

                // let imgUrl = canvas.toDataURL("image/png"); //.replace("image/png", "image/octet-stream");

                // window.plugins.socialsharing.share( 
                //     'test', 
                //     imgUrl, 
                //     imgUrl, 
                //     [function(result) {alert('success'); console.log(result)}], 
                //     [function(result) {alert('error'); console.log(result)}]);

                // document.body.removeChild(ref)
            })
            .catch(err => {
                console.error('Error: ', err)
            });
    }

    handleOnDeleteClicked = (id) => {
        this.props.deleteQuota(id)
    }


    _format = (number) => new Intl.NumberFormat('es-419', { style: 'decimal' }).format(number)

    _fee = (quote) => fees(quote.quota, quote.interestRate, quote.amount)

    render() {
        const { isOpen } = this.props;

        return (
            <CuotasHistoryWrapper isHidden={!isOpen}>
                <HistoryTitle>Historial de cálculos</HistoryTitle>
                {
                    this.state.quotaHistory.length > 0 ?
                        <Whirligig
                            visibleSlides={1.15}>
                            {
                                this.state.quotaHistory
                                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                    .map(quote => {
                                        return (
                                            <HistoryItem id={`id-${quote.id}`} key={quote.id}>
                                                Por <strong>RD$ {this._format(quote.amount)}</strong> <br /> vas a pagar <strong>RD$ {this._format(this._fee(quote))}/ mes</strong> <br />en <strong>{quote.quota} cuotas</strong> <br /> a una <strong>tasa anual de un {quote.interestRate}%</strong> ({(quote.interestRate / 12).toFixed(2)}%/mes).
                                                <Button className="delete-btn" textButton="Borrar" onClickHandle={this.onDeleteClicked.bind(this, quote.id)} />
                                                {/* <Button textButton="Compartir" onClickHandle={this.onShareClicked.bind(this, quote)} /> */}
                                            </HistoryItem>
                                        );
                                    })
                            }
                        </Whirligig>
                        : (<EmptyHistory />)
                }
                <Button textButton="Calcular otro monto" onClickHandle={this.onNewQuotaClicked} />
            </CuotasHistoryWrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState,
    history: state.historyState
})

export default connect(
    mapStateToProps,
    { getQuotaHistory, resetState, deleteQuota }
)(CuotasFinalResults);